const fontSize = '.95rem!important';

const styles = {
  global: {
    'html, body': {
      backgroundColor: 'gray.100',
    },
    '.rdt_TableCol div, .rdt_TableCol_Sortable div': {
      whiteSpace: 'unset',
    },
    '.rdt_TableCol': {
      textTransform: 'none!important',
      paddingLeft: '8px!important',
      paddingRight: '8px!important',
    },
    '.rdt_TableHeadRow, .rdt_TableCol': {
      fontSize: fontSize,
      fontWeight: '700',
      letterSpacing: 'normal!important',
    },
    '.rdt_TableRow.odd': {
      backgroundColor: '#FAFAFA',
    },
    '.rdt_TableCell': {
      whiteSpace: 'break-spaces',
      display: 'table-cell',
      verticalAlign: 'middle',
      paddingLeft: '8px!important',
      paddingRight: '8px!important',
    },
    '.rdt_TableRow, .rdt_TableHeadRow': {
      borderBottom: '1px solid rgba(0,0,0,.12)',
      alignItems: 'center',
    },
    '.rdt_TableRow, tr.rdt_TableRow': {
      fontSize: fontSize,
      '&:hover': {
        backgroundColor: '#e4e5e6',
      },
    },
    '.rdt_Pagination': {
      fontSize: fontSize,
    },
    '.dataLabels': {
      fontSize: '18px',
      letterSpacing: '0',
      color: 'black',
      zIndex: -999,
      textAlign: 'center',
    },
    '.fullscreen-enabled .dataLabels': {
      fontSize: '34px',
      lineHeight: '38px',
    },
    '.daterangepicker': {
      '.calendar-table th, .calendar-table td, select.monthselect, select.yearselect, select.hourselect, select.minuteselect, select.secondselect, select.ampmselect, .ranges li, .drp-selected, .drp-buttons .btn':
        {
          fontSize: '.875rem!important',
        },
    },
    '.chakra-form__error-message': {
      // temp hide
      // display: 'none!important',
    },
    '.draggableRow >.rdt_TableCell': {
      lineHeight: '18px',
    },
    '.draggableRow >.rdt_TableCell .slug': {
      marginTop: '0px',
    },
    '.react-simple-image-viewer__modal': {
      zIndex: '999!important',
      marginTop: '45px!important',
    },
  },
};

export default styles;
