import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  BoxProps,
  Flex,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { Alert } from 'interfaces/Interface';
import { FiChevronDown } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';

import logo from 'assets/images/logo-word.png';
import { useUser, userState } from 'state/User';
import api from 'utils/api';
import { adminRoleOptions } from 'utils/category';
import { findOption } from 'utils/util';

interface NavbarProps extends BoxProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export default function NavbarContent({
  isOpen,
  onOpen,
  onClose,
  ...rest
}: NavbarProps) {
  const navigate = useNavigate();
  const { user } = useUser();
  const setUserState = useSetRecoilState(userState);

  const role = findOption(adminRoleOptions, user?.role || '');

  const logout = async () => {
    let alert: Alert = {};
    try {
      const resp = await api({
        method: 'PATCH',
        url: '/logout',
      });
      alert = {
        message: resp.data.message,
        type: resp.status === 200 ? 'success' : 'error',
      };
    } catch (e: any) {
      alert = {
        message: e.message,
        type: 'error',
      };
    } finally {
      toast(alert.message, {
        type: alert.type,
      });
      setUserState(null);
    }
  };

  const viewProfile = () => {
    navigate('/app/profile');
  };

  return (
    <Box
      position={'fixed'}
      zIndex="overlay"
      w={'100%'}
      bg={useColorModeValue('gray.100', 'gray.900')}
      px={4}
      borderBottom="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
    >
      <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
        <IconButton
          size={'md'}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={'Open Menu'}
          display={{ md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
        />
        <Link to={'/app/home'}>
          <Image maxW={'150px'} src={logo} alt="His Grace Church" />
        </Link>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                {/* <Avatar
              size={'sm'}
              src={
                'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
              }
            /> */}
                <VStack
                  display={{ base: 'flex', md: 'flex' }}
                  alignItems="flex-end"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="md" lineHeight={0.8}>
                    {user?.firstName}
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    {role?.label}
                  </Text>
                </VStack>
                <Box display={{ base: 'flex', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem onClick={viewProfile}>Profil</MenuItem>
              <MenuDivider />
              <MenuItem onClick={logout} color={'red'}>
                Keluar
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Box>
  );
}
