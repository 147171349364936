import { Box, Text, useColorModeValue } from '@chakra-ui/react';

export default function Footer() {
  return (
    <Box
      position={'fixed'}
      w={{ base: '100%', md: 'calc(100vw - 15rem)' }}
      bottom={0}
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
      p="4"
      pl="6"
      borderTop="1px"
      borderTopColor={useColorModeValue('gray.200', 'gray.700')}
    >
      <Text>
        © 2022 Aplikasi <b>{process.env.REACT_APP_CLIENT_PRODUCT} </b>oleh{' '}
        <b>{process.env.REACT_APP_COMPANY_NAME}</b>
      </Text>
    </Box>
  );
}
