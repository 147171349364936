import { MenuItemProps } from 'interfaces/Interface';
import { lazy } from 'react';
import {
  FiCreditCard,
  FiHome,
  FiList,
  FiSettings,
  FiUsers,
} from 'react-icons/fi';

import GeneralSettings from 'pages/Settings/GeneralSettings/GeneralSettings';
import { adminRole } from 'utils/category';

const Campaign = lazy(() => import('pages/Contents/Campaign/ListCampaign'));
const AddCampaign = lazy(() => import('pages/Contents/Campaign/AddCampaign'));
const UpdateCampaign = lazy(
  () => import('pages/Contents/Campaign/UpdateCampaign')
);

const Post = lazy(() => import('pages/Contents/Post/ListPost'));
const AddPost = lazy(() => import('pages/Contents/Post/AddPost'));
const UpdatePost = lazy(() => import('pages/Contents/Post/UpdatePost'));

const Page = lazy(() => import('pages/Contents/Page/ListPage'));
const AddPage = lazy(() => import('pages/Contents/Page/AddPage'));
const UpdatePage = lazy(() => import('pages/Contents/Page/UpdatePage'));

const Event = lazy(() => import('pages/Contents/Event/ListEvent'));
const AddEvent = lazy(() => import('pages/Contents/Event/AddEvent'));
const UpdateEvent = lazy(() => import('pages/Contents/Event/UpdateEvent'));

const Worship = lazy(() => import('pages/Contents/Worship/ListWorship'));
const AddWorship = lazy(() => import('pages/Contents/Worship/AddWorship'));
const UpdateWorship = lazy(
  () => import('pages/Contents/Worship/UpdateWorship')
);

const Reflection = lazy(
  () => import('pages/Contents/Reflection/ListReflection')
);
const AddReflection = lazy(
  () => import('pages/Contents/Reflection/AddReflection')
);
const UpdateReflection = lazy(
  () => import('pages/Contents/Reflection/UpdateReflection')
);

const Summary = lazy(() => import('pages/Dashboard/Summary'));
const AddPayment = lazy(() => import('pages/Payment/AddPayment'));
const UpdatePayment = lazy(() => import('pages/Payment/UpdatePayment'));
const PaymentPending = lazy(() => import('pages/Payment/PaymentPending'));
const PaymentPaid = lazy(() => import('pages/Payment/PaymentPaid'));
const PaymentDetail = lazy(() => import('pages/Payment/PaymentDetail'));

const Branch = lazy(() => import('pages/Branch/ListBranch'));
const BankAccount = lazy(() => import('pages/BankAccount/ListBankAccount'));

const PaymentCategory = lazy(
  () => import('pages/PaymentCategory/ListPaymentCategory')
);
const Customer = lazy(() => import('pages/Customer/ListCustomer'));
const Admin = lazy(() => import('pages/Admin/ListAdmin'));
const Faq = lazy(() => import('pages/Contents/Faq/ListFaq'));

const MyProfile = lazy(() => import('pages/Profile/MyProfile'));
const NotFound = lazy(() => import('pages/NotFound'));

export const SidebarMenuItems: MenuItemProps[] = [
  {
    name: 'Dasbor',
    icon: FiHome,
    roles: [adminRole.ADMIN_HQ, adminRole.ADMIN_COMPANY],
    items: [
      {
        name: 'Rangkuman',
        path: 'summary',
        minRoleLabel: 'HQ',
        roles: [adminRole.ADMIN_HQ, adminRole.ADMIN_COMPANY],
        element: Summary,
      },
    ],
  },
  {
    name: 'Konten',
    icon: FiList,
    roles: [
      adminRole.ADMIN_BRANCH,
      adminRole.ADMIN_HQ,
      adminRole.ADMIN_COMPANY,
    ],
    items: [
      {
        name: 'Berita',
        path: 'post',
        element: Post,
      },
      {
        path: 'post/add',
        element: AddPost,
      },
      {
        path: 'post/update/:id',
        element: UpdatePost,
      },
      {
        name: 'Acara',
        path: 'event',
        element: Event,
      },
      {
        path: 'event/add',
        element: AddEvent,
      },
      {
        path: 'event/update/:id',
        element: UpdateEvent,
      },
      {
        name: 'Ibadah',
        path: 'worship',
        element: Worship,
        minRoleLabel: 'HQ',
        roles: [adminRole.ADMIN_HQ, adminRole.ADMIN_COMPANY],
      },
      {
        path: 'worship/add',
        element: AddWorship,
        roles: [adminRole.ADMIN_HQ, adminRole.ADMIN_COMPANY],
      },
      {
        path: 'worship/update/:id',
        element: UpdateWorship,
        roles: [adminRole.ADMIN_HQ, adminRole.ADMIN_COMPANY],
      },
      {
        name: 'Renungan Harian',
        path: 'reflection',
        element: Reflection,
        minRoleLabel: 'HQ',
        roles: [adminRole.ADMIN_HQ, adminRole.ADMIN_COMPANY],
      },
      {
        path: 'reflection/add',
        element: AddReflection,
        roles: [adminRole.ADMIN_HQ, adminRole.ADMIN_COMPANY],
      },
      {
        path: 'reflection/update/:id',
        element: UpdateReflection,
        roles: [adminRole.ADMIN_HQ, adminRole.ADMIN_COMPANY],
      },
      {
        name: 'HGCbisa',
        path: 'campaign',
        element: Campaign,
        minRoleLabel: 'HQ',
        roles: [adminRole.ADMIN_HQ, adminRole.ADMIN_COMPANY],
      },
      {
        path: 'campaign/add',
        element: AddCampaign,
      },
      {
        path: 'campaign/update/:id',
        element: UpdateCampaign,
      },
      {
        name: 'Halaman',
        path: 'page',
        element: Page,
        minRoleLabel: 'ACH',
        roles: [adminRole.ADMIN_COMPANY],
      },
      {
        path: 'page/add',
        element: AddPage,
        roles: [adminRole.ADMIN_COMPANY],
      },
      {
        path: 'page/update/:id',
        element: UpdatePage,
        roles: [adminRole.ADMIN_COMPANY],
      },
      {
        name: 'FAQ',
        path: 'faq',
        element: Faq,
        minRoleLabel: 'ACH',
        roles: [adminRole.ADMIN_COMPANY],
      },

      // other routes
      {
        path: '*',
        element: NotFound,
      },
      {
        path: 'home',
        element: MyProfile,
      },
      {
        path: 'profile',
        element: MyProfile,
      },
    ],
  },
  {
    name: 'Transaksi',
    icon: FiCreditCard,
    roles: [adminRole.ADMIN_HQ, adminRole.ADMIN_COMPANY],
    items: [
      {
        name: 'Transaksi Berhasil',
        path: 'payments/paid',
        element: PaymentPaid,
        minRoleLabel: 'HQ',
        roles: [adminRole.ADMIN_HQ, adminRole.ADMIN_COMPANY],
      },
      {
        name: 'Transaksi Tertunda',
        path: 'payments/pending',
        element: PaymentPending,
        minRoleLabel: 'ACH',
        roles: [adminRole.ADMIN_COMPANY],
      },
      {
        name: 'Tambah Transaksi',
        path: 'payments/add',
        element: AddPayment,
        minRoleLabel: 'ACH',
        roles: [adminRole.ADMIN_COMPANY],
      },
      {
        path: 'payments/detail/:id',
        element: PaymentDetail,
        roles: [adminRole.ADMIN_HQ, adminRole.ADMIN_COMPANY],
      },
      {
        path: 'payments/update/:id',
        element: UpdatePayment,
        roles: [adminRole.ADMIN_COMPANY],
      },
    ],
  },
  {
    name: 'Pengguna',
    icon: FiUsers,
    roles: [adminRole.ADMIN_HQ, adminRole.ADMIN_COMPANY],
    items: [
      {
        name: 'Pengguna',
        path: 'customers',
        element: Customer,
        minRoleLabel: 'HQ',
        roles: [adminRole.ADMIN_HQ, adminRole.ADMIN_COMPANY],
      },
      {
        name: 'Admin',
        path: 'admins',
        element: Admin,
        minRoleLabel: 'ACH',
        roles: [adminRole.ADMIN_COMPANY],
      },
    ],
  },
  {
    name: 'Pengaturan',
    icon: FiSettings,
    roles: [adminRole.ADMIN_COMPANY],
    items: [
      {
        name: 'Cabang',
        path: 'branch',
        element: Branch,
        minRoleLabel: 'ACH',
        roles: [adminRole.ADMIN_COMPANY],
      },
      {
        name: 'Persembahan',
        path: 'payment-category',
        element: PaymentCategory,
        minRoleLabel: 'ACH',
        roles: [adminRole.ADMIN_COMPANY],
      },
      {
        name: 'Bank Account',
        path: 'bank-account',
        element: BankAccount,
        minRoleLabel: 'ACH',
        roles: [adminRole.ADMIN_COMPANY],
      },
      {
        name: 'Lainnya',
        path: 'general-settings',
        element: GeneralSettings,
        minRoleLabel: 'ACH',
        roles: [adminRole.ADMIN_COMPANY],
      },
    ],
  },
];
