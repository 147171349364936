import {
  Alert,
  AlertIcon,
  Box,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { TypeOptions, toast } from 'react-toastify';

import MyButton from 'components/MyButton';
import api from 'utils/api';

const SETTING_CATEGORY = 'PAYMENT';
const SETTING_MAP = {
  PLATFORM_FEE_FIXED: 'PLATFORM_FEE_FIXED',
  PLATFORM_FEE_PERCENT: 'PLATFORM_FEE_PERCENT',
};

function PlatformFee() {
  const [setting, setSetting] = useState({
    [SETTING_MAP.PLATFORM_FEE_FIXED]: '',
    [SETTING_MAP.PLATFORM_FEE_PERCENT]: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getSettings = useCallback(async () => {
    try {
      const { data } = await api({
        url: '/settings',
        method: 'GET',
        params: {
          category: SETTING_CATEGORY,
        },
      });

      const newSetting = { ...setting };
      Object.keys(SETTING_MAP).forEach((s) => {
        if (data.data[s]) {
          newSetting[s] = data.data[s].value;
        }
      });
      setSetting(newSetting);
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSetting = (name: string, value: string) => {
    setSetting((currentSetting) => ({
      ...currentSetting,
      [name]: value,
    }));
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    let status: TypeOptions = 'error';
    let message = 'Ada yang salah. Silakan coba lagi.';

    setIsSubmitting(true);
    const newSetting = Object.keys(setting).map((s) => ({
      category: SETTING_CATEGORY,
      name: s,
      value: setting[s],
    }));
    await api({
      url: '/settings',
      method: 'PATCH',
      data: {
        settings: newSetting,
      },
    })
      .then((resp) => {
        // console.log('resp', resp);
        status = resp.status === 200 ? 'success' : 'error';
        message = resp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          // console.log('err', err.response);
          status = 'error';
          message = err.response.data.message;
        }
      });

    setIsSubmitting(false);
    toast(message, { type: status });
  };

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <Box
      boxShadow="sm"
      backgroundColor="white"
      // padding="4"
      // maxW="450px"
      // borderRadius="0.25rem"
      // borderWidth="1px"
      // borderColor="hsl(0,0%,80%)"
    >
      <Alert marginBottom={6} status="info">
        <AlertIcon />
        Biaya platform dihitung dari Fixed + persentase (setelah dikurangi dari
        fixed fee)
      </Alert>
      <form onSubmit={onSubmit}>
        <FormControl marginBottom="5">
          <FormLabel>Biaya Platform ACH (Fixed + %)</FormLabel>

          <Flex>
            <Box>
              <InputGroup w={'160px'}>
                <Input
                  textAlign={'left'}
                  value={setting.PLATFORM_FEE_FIXED}
                  onChange={(e) =>
                    updateSetting(
                      SETTING_MAP.PLATFORM_FEE_FIXED,
                      e.target.value
                    )
                  }
                />
                <InputLeftElement
                  zIndex={'unset'}
                  pointerEvents="none"
                  color="gray.400"
                  fontSize="1.2em"
                  children="Rp"
                />
              </InputGroup>
            </Box>
            <Center mx={2}>+</Center>
            <Box>
              <InputGroup w={'100px'}>
                <Input
                  textAlign={'right'}
                  value={setting.PLATFORM_FEE_PERCENT}
                  onChange={(e) =>
                    updateSetting(
                      SETTING_MAP.PLATFORM_FEE_PERCENT,
                      e.target.value
                    )
                  }
                />
                <InputRightElement
                  zIndex={'unset'}
                  pointerEvents="none"
                  color="gray.400"
                  fontSize="1.2em"
                  children="%"
                />
              </InputGroup>
            </Box>
          </Flex>
        </FormControl>

        <Divider marginY="0" />
        <Flex justify="flex-end">
          <MyButton
            colorScheme="green"
            marginTop="4"
            mb="2"
            type="submit"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Save
          </MyButton>
        </Flex>
      </form>
    </Box>
  );
}

export default PlatformFee;
