// theme/index.js
import { extendTheme } from '@chakra-ui/react';

// Component style overrides
// import Button from './components/button'
import Input from './components/input';
// Foundational style overrides
import borders from './foundations/borders';
// Global style overrides
import styles from './styles';

const overrides = {
  styles,
  borders,
  // // Other foundational style overrides go here
  components: {
    // Button,
    Input,
    // Other components go here
  },
};

export default extendTheme(overrides);
