import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React from 'react';

import ContentLayout from 'components/MainLayout/ContentLayout';

import EmailSettings from './Emails';
import PlatformFee from './PlatformFee';

function GeneralSettings() {
  return (
    <ContentLayout title="Pengaturan Lainnya">
      <Box
        boxShadow="sm"
        backgroundColor="white"
        // padding="4"
        maxW="450px"
        borderRadius="0.25rem"
        borderWidth="1px"
        borderColor="hsl(0,0%,80%)"
      >
        <Tabs>
          <TabList>
            <Tab>Biaya Platform</Tab>
            <Tab>Email Layanan</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <PlatformFee />
            </TabPanel>
            <TabPanel>
              <EmailSettings />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </ContentLayout>
  );
}

export default GeneralSettings;
