import {
  CreatorProps,
  EventProps,
  OptionProps,
  WorshipProps,
} from 'interfaces/Interface';

import dayjs from 'utils/dayjs';

import { timeZoneOptions } from './category';

function convertArrayOfObjectsToCSV(array: object[]) {
  let result: any;
  const data = array || null;

  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = Object.keys(data[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item: any) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      result += item[key];
      ctr += 1;
    });
    result += lineDelimiter;
  });

  return result;
}

export function downloadCSV(array: object[], fileName = 'Export.csv') {
  // console.log('array', array);
  const newArray = array.map(({ ...keepAttrs }) => keepAttrs);

  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(newArray);
  if (csv == null) return;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', fileName);
  link.click();
}

export function capitalize(str: string): string {
  if (!str) return '';
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
}

export function formatCurrency(
  number: number,
  maximumFractionDigits = 0,
  showSymbol = true
): string {
  const formatter = showSymbol
    ? new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: maximumFractionDigits,
        minimumFractionDigits: 0,
      })
    : new Intl.NumberFormat('id-ID', {
        maximumFractionDigits: maximumFractionDigits,
        minimumFractionDigits: 0,
      });
  return formatter.format(number);
}

export function convertTimeTo12Hour(time: string): string {
  const [hours, mins] = time.split(':');
  const suffix = Number(hours) >= 12 ? ' PM' : ' AM';
  const convert = ((Number(hours) + 11) % 12) + 1 + ':' + mins + suffix;
  return convert;
}

export function generateSelectOption(
  object: Object,
  sort: boolean
): OptionProps[] {
  const options: OptionProps[] = Object.entries(object).map(([key, value]) => ({
    value: key,
    label: value,
  }));
  if (sort && options.length > 0) {
    const compareLabel = (a: OptionProps, b: OptionProps) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
      return 0;
    };
    options.sort(compareLabel);
  }
  return options;
}

export function sortOptions(options: OptionProps[]) {
  if (!options.length) return options;
  const compareLabel = (a: OptionProps, b: OptionProps) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
    if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
    return 0;
  };
  return options.sort(compareLabel);
}

export function findOption(options: OptionProps[], value: string | undefined) {
  if (typeof value === value) return;
  return options.find(
    (option) => option.value.toLowerCase() === value?.toLowerCase()
  );
}

export function findOptions(options: OptionProps[], values: string[]) {
  if (!values.length) return [];
  const lowerCaseValues = values.map((value) => value.toLowerCase());
  return options.filter((option) =>
    lowerCaseValues.includes(option.value.toLowerCase())
  );
}

export function validateEmail(email: string) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
}

export function validatePassword(password: string) {
  const regex = /^(?=.*[A-Za-z].*)(?=.*[0-9].*)[A-Za-z0-9]{8,}$/;
  return regex.test(String(password).toLowerCase());
}

export function formatCreatorEditor<
  T extends { editor: CreatorProps; creator: CreatorProps }
>(object: T): string {
  const editor = object?.editor ? `(${object.editor?.firstName})` : '';
  const creator = object?.creator?.firstName
    ? `${object.creator?.firstName}\r\n`
    : '';
  return `${creator}${editor}`;
}

export function formatDate(
  date?: string | number,
  showTime: boolean = true,
  userFormat = isThisYear(date?.toString())
    ? `D MMM${showTime ? ', h:mm A' : ''}`
    : `D MMM YYYY${showTime ? ', h:mm A' : ''}`
) {
  if (typeof date === undefined || !date) {
    return '';
  }
  if (typeof date === 'number') {
    return dayjs.unix(date).format(userFormat);
  }
  return dayjs(date).format(userFormat);
}

export function formatDateEvent(row: EventProps | WorshipProps) {
  const startDate = row.startDate
    ? formatDate(row.startDate.toString(), false)
    : '';
  const startTime = row.startTime
    ? convertTimeTo12Hour(row.startTime.toString())
    : '';
  const tz = row.timeZone
    ? findOption(timeZoneOptions, row.timeZone)?.label
    : '';
  return `${startDate}\r\n${startTime} ${tz}`;
}

export function isThisYear(date: string | undefined) {
  if (!date) return false;
  return new Date(date).getFullYear === new Date().getFullYear;
}

export function formatCreatedUpdateDate<
  T extends { createdAt: string; updatedAt: string }
>(object: T): string {
  const createdAt = object?.createdAt ? formatDate(object.createdAt) : '';
  const updatedAt = object?.updatedAt
    ? `(${formatDate(object.updatedAt)})`
    : '';
  return `${createdAt}\r\n${updatedAt}`;
}

export function shortenDateRange(start: dayjs.Dayjs, end: dayjs.Dayjs) {
  // shorten dateRange
  switch (true) {
    case start.format('D MMM YYYY') === end.format('D MMM YYYY'):
      return start.format('D MMM YYYY');
    case start.format('MMM YYYY') === end.format('MMM YYYY'):
      return `${start.format('MMM D')} - ${end.format('D')} ${end.format(
        'YYYY'
      )}`;
    case start.format('YYYY') === end.format('YYYY'):
      return `${start.format('D MMM')} - ${end.format('D MMM')} ${end.format(
        'YYYY'
      )}`;
    default:
      return `${start.format('D MMM YYYY')} - ${end.format('D MMM YYYY')}`;
  }
}

export const isEmpty = (htmlString: string) => {
  const parser = new DOMParser();

  const { textContent } = parser.parseFromString(
    htmlString,
    'text/html'
  ).documentElement;
  if (textContent == null) return;
  return !textContent.trim();
};

export const isObjectEmpty = (obj: Object) => {
  return Object.keys(obj).length === 0;
};
