const Input = {
  // baseStyle: {
  //   field: {
  //     borderRadius: '0',
  //     // borderColor: 'primary.accent2',
  //     // _hover: {
  //     //   borderColor: 'primary.foreground',
  //     // },
  //   }
  // },
  // sizes: {},
  // variants: {},
  variants: {
    outline: {
      field: {
        borderRadius: '4px',
        borderColor: 'hsl(0, 0%, 80%)',
        _hover: {
          borderColor: 'hsl(0, 0%, 70%)',
        },
      },
      addon: {
        borderRadius: '4px',
        borderColor: 'hsl(0, 0%, 80%)',
        _hover: {
          borderColor: 'hsl(0, 0%, 70%)',
        },
      },
    },
  },
  defaultProps: {
    // size: null
  },
};

export default Input;
