import { Flex, Link } from '@chakra-ui/react';
import {
  Link as RouterLink,
  useMatch,
  useResolvedPath,
} from 'react-router-dom';
import type { LinkProps } from 'react-router-dom';

export const SidebarLink = ({ children, to, ...props }: LinkProps) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });

  return (
    <div>
      <Link as={RouterLink} to={to} {...props}>
        <Flex
          align="center"
          px={{ base: 4, md: 3 }}
          py="2"
          mx="3"
          borderRadius="md"
          role="group"
          cursor="pointer"
          _hover={{
            bgGradient: 'linear(to-r, blue.400, blue.500, blue.600)',
            textDecoration: 'underline',
            color: 'white',
          }}
          // backgroundColor={match ? '#4899C2' : 'transparent'}
          bgGradient={
            match ? 'linear(to-r, blue.400, blue.500, blue.600)' : 'transparent'
          }
          color={match ? 'white' : 'unset'}
        >
          {children}
        </Flex>
      </Link>
    </div>
  );
};
