import { AdminProps } from 'interfaces/Interface';
import { FC, useCallback, useEffect } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import api from 'utils/api';

export const userState = atom<null | AdminProps>({
  key: 'userState',
  default: null,
});

export const userFetchingState = atom<boolean>({
  key: 'userFetchingState',
  default: true,
});

export const useUser = () => {
  const isFetching = useRecoilValue(userFetchingState);
  const user = useRecoilValue(userState);

  return {
    isFetching,
    user,
  };
};

export const UserRoot: FC = () => {
  const setFetchingState = useSetRecoilState(userFetchingState);
  const setUserState = useSetRecoilState(userState);

  const fetchUser = useCallback(async () => {
    try {
      setFetchingState(true);
      const { data } = await api({
        method: 'GET',
        url: '/profile',
      });
      setUserState(data.user);
      // console.log('user', data.user);
    } catch (e) {
      setUserState(null);
    } finally {
      setFetchingState(false);
    }
  }, [setFetchingState, setUserState]);

  api.interceptors.response.use(
    (config) => config,
    (error) => {
      if (error.response.status === 401) {
        setUserState(null);
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return null;
};
