import 'react-toastify/dist/ReactToastify.css';

import { ChakraProvider, Spinner } from '@chakra-ui/react';
import { FC, Suspense, lazy, useEffect } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';
import theme from 'theme';

import CenterLayout from 'components/CenterLayout';
import MainLayout from 'components/MainLayout';
import { SidebarMenuItems } from 'components/Sidebar/SidebarMenuItems';
import { UserRoot, useUser } from 'state/User';

const Login = lazy(() => import('pages/Auth/Login'));
const CreatePassword = lazy(() => import('pages/Auth/CreatePassword'));
const ForgotPassword = lazy(() => import('pages/Auth/ForgotPassword'));

const LoggedinRoutes: FC = () => {
  const { isFetching, user } = useUser();
  const navigate = useNavigate();

  const notLoggedin = !isFetching && !user;

  useEffect(() => {
    if (notLoggedin) {
      navigate('/login');
    }
  });

  if (isFetching) {
    return <Spinner m="6" />;
  }

  if (notLoggedin) {
    return <div>Redirecting...</div>;
  }

  return (
    <MainLayout>
      <Routes>
        {/* GENERATE ROUTE BASED ON MENU LIST */}
        {SidebarMenuItems.map((e) => {
          return e.items.map((item) => {
            if (
              item.element &&
              (typeof item?.roles === 'undefined' ||
                item?.roles?.includes(user?.role!))
            ) {
              return <Route path={item.path} element={<item.element />} />;
            }
            return null;
          });
        })}
      </Routes>
    </MainLayout>
  );
};

const LoggedoutRoutes: FC = () => {
  return (
    <CenterLayout>
      <Routes>
        <Route path="*" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/create-password/:registrationToken"
          element={<CreatePassword />}
        />
        <Route
          path="/reset-password/:registrationToken"
          element={<CreatePassword />}
        />
      </Routes>
    </CenterLayout>
  );
};

function App() {
  return (
    <RecoilRoot>
      <ChakraProvider theme={theme}>
        <>
          <Suspense fallback={<Spinner m="6" />}>
            <Router>
              <Routes>
                <Route path="/app/*" element={<LoggedinRoutes />} />
                <Route path="/app" element={<Navigate to="/app/home" />} />
                <Route path="*" element={<LoggedoutRoutes />} />
              </Routes>
            </Router>
          </Suspense>
          <UserRoot />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            closeOnClick
            theme="colored"
          />
        </>
      </ChakraProvider>
    </RecoilRoot>
  );
}

export default App;
