import { Badge, BadgeProps } from '@chakra-ui/react';

interface MyBadgeProps extends BadgeProps {
  label?: string;
}

export default function MyBadge({ label, ...rest }: MyBadgeProps) {
  let color: string;
  switch (label) {
    case 'ACH': {
      color = 'red';
      break;
    }
    case 'HQ': {
      color = 'orange';
      break;
    }
    default: {
      color = '';
      break;
    }
  }
  if (label) {
    return (
      <Badge colorScheme={color} fontSize="0.5em" ml={1} {...rest}>
        {label}
      </Badge>
    );
  }
  return null;
}
