import {
  Box,
  Drawer,
  DrawerContent,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

import SidebarContent from 'components/Sidebar/SidebarContent';

import Footer from './Footer';
import NavbarContent from './NavbarContent';

export default function MainLayout({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <NavbarContent isOpen={isOpen} onClose={onClose} onOpen={onOpen} />

      <Box pt={16} minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: 'none', md: 'block' }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          // size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>

        <Box ml={{ base: 0, md: 60 }} p="0" pb="0">
          {children}
          <Footer />
        </Box>
      </Box>
    </>
  );
}
