import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

interface ContentLayoutProps {
  title?: string;
  breadcrumb?: React.ReactNode;
  children: React.ReactNode;
}

function ContentLayout({ title, breadcrumb, children }: ContentLayoutProps) {
  return (
    <>
      <Box
        position="fixed"
        zIndex="99"
        w={{ base: '100%', md: 'calc(100vw - 15rem)' }}
        paddingY="3"
        px={{ base: 4, md: 8 }}
        backgroundColor="white"
        borderBottom="1px"
        borderColor="hsl(0,0%,80%)"
        borderTop="1px"
        borderTopColor={useColorModeValue('gray.200', 'gray.700')}
      >
        {title && (
          <Text
            fontSize="1.1rem"
            fontWeight={'semibold'}
            lineHeight="2.5rem"
            marginX="0.5"
          >
            {title}
          </Text>
        )}
        {breadcrumb && breadcrumb}
      </Box>

      <Box
        padding="9"
        pt="24"
        pb={{ base: '24', md: '20' }}
        px={{ base: 4, md: 9 }}
      >
        {children}
      </Box>
    </>
  );
}

export default ContentLayout;
