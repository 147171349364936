export const feeCoverOptions = [
  { value: 'NO', label: 'No. Istiqlal will cover transaction fee' },
  { value: 'YES', label: 'Yes. User must pay transaction fee.' },
  { value: 'OPTIONAL', label: 'Optional. Allow user to choose.' },
];

export const paymentSourceOptions = [
  { value: 'CARD', label: 'Kartu' },
  { value: 'CASH', label: 'Kas' },
];

export const publishedStatusOptions = [
  { value: 'PUBLISHED', label: 'PUBLISHED' },
  { value: 'DRAFT', label: 'DRAFT' },
];

// App: online payment via mobile FE
// Web: online payment via web FE
// Onsite: onsite/local payment other than above
export const paymentMethodOptions = [
  { value: 'APP', label: 'Aplikasi' },
  { value: 'ONSITE', label: 'Onsite' },
];

export const paymentTypeOptions = [
  { value: true, label: 'Donation' },
  { value: false, label: 'Payment' },
];

export const paymentCycleOptions = [
  { value: false, label: 'One-Time' },
  { value: true, label: 'Recurring' },
];

export const occurrenceOptions = [
  { value: 'NO-REPEAT', label: 'Sekali' },
  { value: 'DAILY', label: 'Harian' },
  { value: 'WEEKLY', label: 'Mingguan' },
  { value: 'BI-WEEKLY', label: '2-Mingguan' },
  { value: 'MONTHLY', label: 'Bulanan' },
];

export const adminRole = {
  ADMIN_BRANCH: 'ADMIN_BRANCH',
  ADMIN_HQ: 'ADMIN_HQ',
  ADMIN_COMPANY: 'ADMIN_COMPANY',
};

export const adminRoleOptions = [
  { value: adminRole.ADMIN_COMPANY, label: 'Admin ACH' },
  { value: adminRole.ADMIN_HQ, label: 'Admin Pusat' },
  { value: adminRole.ADMIN_BRANCH, label: 'Admin Cabang' },
];

export const adminStatusOptions = [
  { value: 'ACTIVE', label: 'Aktif' },
  { value: 'INACTIVE', label: 'Tidak Aktif' },
];

export const donorStatusOptions = [
  { value: 'INITIATED', label: 'Baru dibuat' },
  { value: 'ACTIVE', label: 'Aktif' },
  { value: 'INACTIVE', label: 'Tidak Aktif' },
];

export const paymentStatusOptions = [
  { value: 'INITIATED', label: 'Dimulai' },
  { value: 'PENDING', label: 'Menunggu Verifikasi Admin' },
  { value: 'PAID', label: 'Berhasil/Lunas' },
];

export const genderOptions = [
  { value: 'MALE', label: 'Laki-laki' },
  { value: 'FENALE', label: 'Perempuan' },
];

export const marriedStatusOptions = [
  { value: 'SINGLE', label: 'Belum menikah' },
  { value: 'MARRIED', label: 'Menikah' },
  { value: 'DIVORCE', label: 'Cerai' },
];

export const volunteerOptions = [
  { value: 'YES', label: 'Ya' },
  { value: 'NO', label: 'Tidak' },
];

export const timeZoneOptions = [
  { value: 'Asia/Jakarta', label: 'WIB' },
  { value: 'Asia/Makassar', label: 'WITA' },
  { value: 'Asia/Jayapura', label: 'WIT' },
];
