import {
  Alert,
  AlertIcon,
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
} from '@chakra-ui/react';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { TypeOptions, toast } from 'react-toastify';

import MyButton from 'components/MyButton';
import api from 'utils/api';

const SETTING_CATEGORY = 'EMAIL_SETTINGS';
const SETTING_MAP = {
  COMPANY_EMAIL: 'COMPANY_EMAIL',
  CLIENT_EMAIL: 'CLIENT_EMAIL',
};

function EmailSettings() {
  const [setting, setSetting] = useState({
    [SETTING_MAP.COMPANY_EMAIL]: '',
    [SETTING_MAP.CLIENT_EMAIL]: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getSettings = useCallback(async () => {
    try {
      const { data } = await api({
        url: '/settings',
        method: 'GET',
        params: {
          category: SETTING_CATEGORY,
        },
      });

      const newSetting = { ...setting };
      Object.keys(SETTING_MAP).forEach((s) => {
        if (data.data[s]) {
          newSetting[s] = data.data[s].value;
        }
      });
      setSetting(newSetting);
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSetting = (name: string, value: string) => {
    setSetting((currentSetting) => ({
      ...currentSetting,
      [name]: value,
    }));
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    let status: TypeOptions = 'error';
    let message = 'Ada yang salah. Silakan coba lagi.';

    setIsSubmitting(true);
    const newSetting = Object.keys(setting).map((s) => ({
      category: SETTING_CATEGORY,
      name: s,
      value: setting[s],
    }));
    await api({
      url: '/settings',
      method: 'PATCH',
      data: {
        settings: newSetting,
      },
    })
      .then((resp) => {
        console.log('resp', resp);
        status = resp.status === 200 ? 'success' : 'error';
        message = resp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          // console.log('err', err.response);
          status = 'error';
          message = err.response.data.message;
        }
      });

    setIsSubmitting(false);
    toast(message, { type: status });
  };

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <Box boxShadow="sm" backgroundColor="white" maxW="450px">
      <Alert marginBottom={6} status="info">
        <AlertIcon />
        Email-email di bawah ini akan digunakan dalam aplikasi.
      </Alert>

      <form onSubmit={onSubmit}>
        <SimpleGrid spacing="6">
          <FormControl>
            <FormLabel>Email Layanan Pengguna ACH</FormLabel>
            <Input
              type={'email'}
              value={setting.COMPANY_EMAIL}
              onChange={(e) =>
                updateSetting(SETTING_MAP.COMPANY_EMAIL, e.target.value)
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Email Layanan Pengguna HGC</FormLabel>
            <Input
              type={'email'}
              value={setting.CLIENT_EMAIL}
              onChange={(e) =>
                updateSetting(SETTING_MAP.CLIENT_EMAIL, e.target.value)
              }
            />
          </FormControl>
        </SimpleGrid>

        <Divider marginTop="6" />
        <Flex justify="flex-end">
          <MyButton
            colorScheme="green"
            marginTop="4"
            mb="2"
            type="submit"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Save
          </MyButton>
        </Flex>
      </form>
    </Box>
  );
}

export default EmailSettings;
