import 'dayjs/locale/id';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
// import AdvancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.locale('id');
dayjs.extend(utc);
dayjs.extend(isBetween);
// dayjs.extend(customParseFormat);
// dayjs.extend(AdvancedFormat);

export default dayjs;
