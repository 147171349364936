import { Button, ButtonProps } from '@chakra-ui/react';
import { ReactText } from 'react';

interface MyButtonProps extends ButtonProps {
  children: ReactText;
  colorScheme: string;
}

export default function MyButton({
  colorScheme,
  children,
  ...rest
}: MyButtonProps) {
  let bgGradient: string | {};
  switch (colorScheme) {
    case 'blue': {
      bgGradient = 'linear(to-r, blue.400, blue.500, blue.600)';
      break;
    }
    case 'green': {
      bgGradient = 'linear(to-r, green.400, green.500, green.600)';
      break;
    }
    case 'red': {
      bgGradient = 'linear(to-r, red.400, red.500, red.600)';
      break;
    }
    default: {
      bgGradient = {};
      break;
    }
  }
  return (
    <Button colorScheme={colorScheme} bgGradient={bgGradient} {...rest}>
      {children}
    </Button>
  );
}
