import { Container, Flex } from '@chakra-ui/react';
import { FC } from 'react';

const CenterLayout: FC = ({ children }) => {
  return (
    <Flex minHeight="100vh" alignItems="center" justifyContent="center">
      <Container maxW="container.sm" p={{ base: 2, md: 4 }}>
        {children}
      </Container>
    </Flex>
  );
};

export default CenterLayout;
