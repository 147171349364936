import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  CloseButton,
  Flex,
  FlexProps,
  Icon,
  Spacer,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactText } from 'react';
import { useLocation } from 'react-router-dom';

import MyBadge from 'components/MyBadge';
import { useUser } from 'state/User';

import { SidebarLink } from './SidebarLink';
import { SidebarMenuItems } from './SidebarMenuItems';

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

export default function SidebarContent({ onClose, ...rest }: SidebarProps) {
  const { user } = useUser();
  const location = useLocation();
  const path = location.pathname.replace('/app/', '');
  const sidebarByRole = SidebarMenuItems.filter((o) =>
    o.items.some(
      (item) =>
        typeof item?.roles === 'undefined' || item?.roles?.includes(user?.role!)
    )
  );

  const accordionIndex = sidebarByRole.findIndex(
    (sidebar) =>
      sidebar.items.findIndex((item) =>
        path.includes(item.path.replace(':id', ''))
      ) !== -1
  );
  // console.log('sidebarByRole', sidebarByRole);
  // console.log('path', path);

  return (
    <Box
      overflowY="scroll"
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        h="16"
        display={{ base: 'flex', md: 'none' }}
        alignItems="center"
        mx="4"
        justifyContent="space-between"
      >
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <Accordion
        pb={24}
        defaultIndex={[accordionIndex]}
        allowToggle
        allowMultiple
      >
        {SidebarMenuItems.map((link) => {
          if (
            typeof link?.roles === 'undefined' ||
            link?.roles?.includes(user?.role!)
          ) {
            return (
              <AccordionItem key={link.name}>
                <Flex>
                  <AccordionButton _expanded={{ bg: 'black', color: 'white' }}>
                    <Box flex="1" textAlign="left">
                      <Icon
                        mr="3"
                        fontSize="16"
                        _groupHover={{
                          color: 'white',
                        }}
                        as={link.icon}
                      />
                      {link.name}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </Flex>

                <AccordionPanel pb={2} px={0}>
                  {link.items.map((link) => {
                    if (
                      link?.name &&
                      (typeof link?.roles === 'undefined' ||
                        link?.roles?.includes(user?.role!))
                    ) {
                      return (
                        <NavItem key={link.name} path={link.path}>
                          {link.name}
                          <Spacer />
                          <MyBadge label={link.minRoleLabel} />
                        </NavItem>
                      );
                    }
                    return null;
                  })}
                </AccordionPanel>
              </AccordionItem>
            );
          }
          return null;
        })}
      </Accordion>
    </Box>
  );
}

interface NavItemProps extends FlexProps {
  children: React.ReactNode | ReactText | string | null | undefined;
  path?: string;
}

const NavItem = ({ path, children, ...rest }: NavItemProps) => {
  return <SidebarLink to={'/app/' + path}>{children}</SidebarLink>;
};
